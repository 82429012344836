<!-- start modal popup order detail (dialog_order)-->
<template>
  <v-row justify="center">
    <v-dialog
      v-model="is_show"
      content-class="dialogorderdetail"
      width="1000px"
    >
      <v-card class="pt-5">
        <v-card-title class="px-3 px-md-5">
          <v-col cols="6" md="6" no-gutters class="ma-0 pa-0">
            <span class="text-h5">{{ $t("page.profile.lab_result") }}</span>
            <br />
            <v-menu offset-y>
              <template
                v-slot:activator="{ on, attrs }"
                v-if="item.list.data.length > 0"
              >
                <v-btn
                  small
                  color="#FFF"
                  text
                  class="btn-download-report"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left dark> mdi-download </v-icon>
                  {{ $t("download") }} PDF
                </v-btn>
              </template>
              <div class="popup">
                <v-row>
                  <v-col cols="12" class="pa-2 mt5 mb10">
                    <v-btn
                      v-if="item.type_test == 'LAB'"
                      small
                      text
                      @click="
                        downloadLab(
                          item.list.data[0].document_information
                            .number_of_report,
                          'simple'
                        )
                      "
                    >
                      <v-icon left dark> mdi-file-document </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("simple") }}</span>
                        <span class="title-grid-c">{{
                          $t("page.profile.desc_simple")
                        }}</span>
                      </div>
                    </v-btn>
                    <v-btn
                      v-if="item.type_test == 'BUY PRODUCT'"
                      small
                      text
                      @click="
                        downloadLab(
                          item.list.data[0].document_information
                            .number_of_report,
                          'simple'
                        )
                      "
                    >
                      <v-icon left dark> mdi-file-document </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("simple") }}</span>
                        <span class="title-grid-c">{{
                          $t("page.profile.desc_simple")
                        }}</span>
                      </div>
                    </v-btn>
                    <v-btn
                      v-if="item.type_test == 'MCU'"
                      small
                      text
                      @click="
                        downloadMcu(
                          item.list.data[0].document_information
                            .number_of_report,
                          'simple'
                        )
                      "
                    >
                      <v-icon left dark> mdi-file-document </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("simple") }}</span>
                        <span class="title-grid-c">{{
                          $t("page.profile.desc_simple")
                        }}</span>
                      </div>
                    </v-btn>
                    <v-btn
                      v-if="item.type_test == 'BUY PACKAGE'"
                      color="#fff"
                      small
                      text
                      class="btn-download-report"
                      @click="
                        downloadMcu(
                          item.list.data[0].document_information
                            .number_of_report,
                          'simple'
                        )
                      "
                    >
                      <v-icon left dark> mdi-file-document </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("simple") }}</span>
                        <span class="title-grid-c">{{
                          $t("page.profile.desc_simple")
                        }}</span>
                      </div>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="pa-2 mb5">
                    <v-btn
                      v-if="item.type_test == 'LAB'"
                      small
                      text
                      @click="
                        downloadLab(
                          item.list.data[0].document_information
                            .number_of_report,
                          'advance'
                        )
                      "
                    >
                      <v-icon left dark color="black">
                        mdi-chart-bar-stacked
                      </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("advance") }}</span>
                        <span class="title-grid-c">{{
                          $t("page.profile.desc_advance")
                        }}</span>
                      </div>
                    </v-btn>
                    <v-btn
                      v-if="item.type_test == 'BUY PRODUCT'"
                      small
                      text
                      @click="
                        downloadLab(
                          item.list.data[0].document_information
                            .number_of_report,
                          'advance'
                        )
                      "
                    >
                      <v-icon left dark color="black">
                        mdi-chart-bar-stacked
                      </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("advance") }}</span>
                        <span class="title-grid-c">{{
                          $t("page.profile.desc_advance")
                        }}</span>
                      </div>
                    </v-btn>
                    <v-btn
                      v-if="item.type_test == 'MCU'"
                      small
                      text
                      @click="
                        downloadMcu(
                          item.list.data[0].document_information
                            .number_of_report,
                          'advance'
                        )
                      "
                    >
                      <v-icon left dark color="black">
                        mdi-chart-bar-stacked
                      </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("advance") }}</span>
                        <span class="title-grid-c">{{
                          $t("page.profile.desc_advance")
                        }}</span>
                      </div>
                    </v-btn>
                    <v-btn
                      v-if="item.type_test == 'BUY PACKAGE'"
                      color="#fff"
                      small
                      text
                      class="btn-download-report"
                      @click="
                        downloadMcu(
                          item.list.data[0].document_information
                            .number_of_report,
                          'advance'
                        )
                      "
                    >
                      <v-icon left dark color="black">
                        mdi-chart-bar-stacked
                      </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("advance") }}</span>
                        <span class="title-grid-c">{{
                          $t("page.profile.desc_advance")
                        }}</span>
                      </div>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-menu>
            <!--
            <template v-if="item.list.data.length > 0">
              <v-btn
                v-if="item.type_test == 'LAB'"
                color="#fff"
                small
                text
                class="btn-download-report"
                @click="
                  downloadLab(
                    item.list.data[0].document_information.number_of_report
                  )
                "
              >
                <v-icon left dark> mdi-download </v-icon>
                {{ $t("download") }} PDF
              </v-btn>
              <v-btn
                v-if="item.type_test == 'BUY PRODUCT'"
                color="#fff"
                small
                text
                class="btn-download-report"
                @click="
                  downloadLab(
                    item.list.data[0].document_information.number_of_report
                  )
                "
              >
                <v-icon left dark> mdi-download </v-icon>
                {{ $t("download") }} PDF
              </v-btn>
              <v-btn
                v-if="item.type_test == 'MCU'"
                color="#fff"
                small
                text
                class="btn-download-report"
                @click="
                  downloadMcu(
                    item.list.data[0].document_information.number_of_report
                  )
                "
              >
                <v-icon left dark> mdi-download </v-icon>
                {{ $t("download") }} PDF
              </v-btn>
              <v-btn
                v-if="item.type_test == 'BUY PACKAGE'"
                color="#fff"
                small
                text
                class="btn-download-report"
                @click="
                  downloadMcu(
                    item.list.data[0].document_information.number_of_report
                  )
                "
              >
                <v-icon left dark> mdi-download </v-icon>
                {{ $t("download") }} PDF
              </v-btn>
            </template>
            -->
          </v-col>
          <v-col cols="6" md="6" no-gutters class="ma-0 pa-0">
            <img
              src="/iconVirtu.png"
              alt="Icon Virtu"
              class="float-right dialogvirtulogo"
            />
          </v-col>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-3 px-md-5">
          <v-row class="pt-5">
            <v-col cols="12">
              <h3 class="black--text">{{ $t("document_information") }}</h3>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table class="super-dense" mobile-breakpoint="0">
                      <template v-slot:default>
                        <tbody class="no-pad tbl">
                          <tr class="grey-color head-vir2">
                            <th class="text-center tb-wide">
                              {{ $t("field.lab_report_no") }}
                            </th>
                            <th class="text-center tb-wide2">
                              {{ $t("page.profile.inspection_date") }}
                            </th>
                            <th class="text-center tb-wide">
                              {{ $t("field.branch_code") }}
                            </th>
                            <th class="text-center tb-wide2">
                              {{ $t("field.branch_name") }}
                            </th>
                          </tr>
                          <tr v-if="item.list.data.length > 0">
                            <td class="text-center">
                              {{
                                item.list.data[0].document_information
                                  .number_of_report
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                formatDateTime(
                                  item.list.data[0].document_information
                                    .datetime_observation
                                )
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                item.list.data[0].document_information
                                  .plant_code
                              }}
                            </td>
                            <td class="text-center">
                              {{ item.list.data[0].document_information.plant }}
                            </td>
                          </tr>
                          <tr v-else>
                            <td colspan="4" class="text-center">
                              {{ $t("field.nomor_data") }}
                            </td>
                          </tr>
                          <tr class="grey-color head-vir2">
                            <th class="text-center">
                              {{ $t("field.medical_record_no") }}
                            </th>
                            <th class="text-center tb-wide">
                              {{ $t("field.primary_doctor") }}
                            </th>
                            <th class="text-center">
                              {{ $t("field.gender") }}
                            </th>
                            <th class="text-center">
                              {{ $t("field.lab_chief") }}
                            </th>
                          </tr>
                          <tr v-if="item.list.data.length > 0">
                            <td class="text-center">
                              {{
                                item.list.data[0].document_information
                                  .medical_record_number
                              }}
                            </td>
                            <td class="text-center tb-wide2">
                              {{
                                item.list.data[0].document_information
                                  .primary_doctor
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                item.list.data[0].document_information[$i18n.locale == "id"?`gender_name_id`
                                : `gender`]
                              }}
                            </td>
                            <td class="text-center tb-wide2">
                              {{
                                item.list.data[0].document_information.lab_chief
                              }}
                            </td>
                          </tr>
                          <tr v-else>
                            <td colspan="4" class="text-center">
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>
            </v-col>
          </v-row>
          <v-row> </v-row>
        </v-card-text>
        <v-card-text class="px-3 px-md-5">
          <v-row>
            <v-col cols="12">
              <h3 class="black--text">
                {{ $t("page.cart.customer_information") }}
              </h3>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table class="super-dense no-hover-table">
                      <template v-slot:default>
                        <tbody
                          class="no-pad desktop"
                          v-if="item.list.data.length > 0"
                        >
                          <tr>
                            <td>
                              <b>{{
                                item.list.data[0].customers_information
                                  .patient_name
                              }}</b>
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.mobile_phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        item.list.data[0].customers_information
                                          .phone_cellular !== null
                                          ? item.list.data[0]
                                              .customers_information
                                              .phone_cellular
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>

                            </td>
                          </tr>
                          <tr>
                            <td>
                              {{
                                `${item.list.data[0].customers_information.address.toUpperCase()}`
                              }}
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        item.list.data[0].customers_information
                                          .phone_number !== null
                                          ? item.list.data[0]
                                              .customers_information
                                              .phone_number
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                              
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {{
                                `${item.list.data[0].customers_information.city}, ${item.list.data[0].customers_information.district}`
                              }}
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.email") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        item.list.data[0].customers_information
                                          .email !== null
                                          ? item.list.data[0].customers_information.email.toUpperCase()
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {{
                                `${item.list.data[0].customers_information.province} ${item.list.data[0].customers_information.postal_code}, ${item.list.data[0].customers_information.country}`
                              }}
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.dob") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${formatedDate(
                                        item.list.data[0].customers_information,
                                        "DD MMM YYYY"
                                      ).toUpperCase()} | ${item.list.data[0].customers_information[$i18n.locale == "id"?`patient_age_id`
                                : `patient_age`].toUpperCase()}`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                        </tbody>
                        <tbody
                          class="no-pad mobile"
                          v-if="item.list.data.length > 0"
                        >
                          <tr>
                            <td>
                              <b>{{
                                item.list.data[0].customers_information
                                  .patient_name
                              }}</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {{
                                `${item.list.data[0].customers_information.address.toUpperCase()}`
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {{
                                `${item.list.data[0].customers_information.city}, ${item.list.data[0].customers_information.district}`
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {{
                                `${item.list.data[0].customers_information.province} ${item.list.data[0].customers_information.postal_code}, ${item.list.data[0].customers_information.country}`
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.mobile_phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        item.list.data[0].customers_information
                                          .phone_cellular !== null
                                          ? item.list.data[0]
                                              .customers_information
                                              .phone_cellular
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        item.list.data[0].customers_information
                                          .phone_number !== null
                                          ? item.list.data[0]
                                              .customers_information
                                              .phone_number
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                              
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.email") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        item.list.data[0].customers_information
                                          .email !== null
                                          ? item.list.data[0].customers_information.email.toUpperCase()
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("field.dob") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${item.list.data[0].customers_information.dob.toUpperCase()} | ${item.list.data[0].customers_information[$i18n.locale == "id"?`patient_age_id`
                                : `patient_age`].toUpperCase()}`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td class="text-center">{{ $t("no_data") }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>
            </v-col>
          </v-row>
          <v-row> </v-row>
        </v-card-text>
        <v-card-text class="px-3 px-md-5">
          <v-row>
            <v-col cols="12">
              <h3 class="black--text">
                {{ $t("page.profile.inspection_result") }}
              </h3>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table class="table-sticky super-dense">
                      <template v-slot:default>
                        <thead class="no-pad">
                          <tr class="grey-color head-vir2">
                            <th class="text-center tb-wide" style="width: 25%">
                              {{ $t("page.profile.inspection_code") }}
                            </th>
                            <th class="text-center tb-wide" style="width: 20%">
                              {{ $t("page.profile.inspection_name") }}
                            </th>
                            <th class="text-center tb-wide" style="width: 15%">
                              {{ $t("result") }}
                            </th>
                            <th class="text-center tb-wide" style="width: 15%">
                              {{ $t("page.profile.reference_result") }}
                            </th>
                            <th class="text-center" style="width: 10%">
                              {{ $t("page.profile.unit") }}
                            </th>
                            <th class="text-center" style="width: 15%">
                              {{ $t("page.profile.notes") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody class="no-pad">
                          <template v-for="(item, index) in item.list.data">
                            <tr
                              :key="item.product.product_code"
                              class="grey-color"
                            >
                              <td colspan="6" class="category-blue">
                                {{ item.product.product_subcategory }}
                              </td>
                            </tr>
                            <tr :key="index" class="grey-color">
                              <td colspan="6" class="bold">
                                {{ item.product.product_code }} ||
                                {{ item.product.product_name }}
                              </td>
                            </tr>
                            <tr
                              v-for="child in item.product_item"
                              :key="child.id"
                              class=""
                            >
                              <td
                                class="text-left"
                                :class="{
                                  true: child.critical_status == true,
                                  false: child.critical_status == false,
                                }"
                              >
                                <v-icon
                                  color="red"
                                  small
                                  v-if="child.critical_status"
                                  style="font-size: 12px !important"
                                >
                                  mdi-alert
                                </v-icon>
                                {{ child.product_item_code }}
                              </td>
                              <td class="text-left">
                                {{ child.product_item_name }}
                              </td>
                              <td
                                class="text-right"
                                :class="{
                                  true: child.critical_status == true,
                                  false: child.critical_status == false,
                                }"
                              >
                                {{ child.result_value_post }}
                              </td>
                              <td class="text-right">
                                {{ child.reference_text }}
                              </td>
                              <td class="text-right">
                                {{ child.item_unit }}
                              </td>
                              <td class="text-left">
                                {{ child.status }}
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>
            </v-col>
          </v-row>
          <v-row> </v-row>
        </v-card-text>
        <v-card-text class="px-3 px-md-5">
          <v-row>
            <v-col cols="12">
              <h3 class="font-black">{{ $t("page.profile.notes") }}</h3>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody
                          class="no-pad desktop"
                          v-if="item.list.note != null"
                        >
                          <tr class="hover-apri">
                            <td>
                              {{
                                `${
                                  item.list.note != null ? item.list.note : "-"
                                }`
                              }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody
                          class="no-pad mobile"
                          v-if="item.list.note != null"
                        >
                          <tr class="hover-apri">
                            <td>
                              {{
                                `${
                                  item.list.note != null ? item.list.note : "-"
                                }`
                              }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr class="hover-apri">
                            <td class="text-center">{{ $t("no_data") }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>
            </v-col>
          </v-row>
          <v-row> </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary darken-1" text @click="toggleDialog()">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Card from "@/components/v2/card/index.vue";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
import moment from "moment";

export default {
  components: {
    Card,
  },
  props: {
    tampilkan: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({
        list: {
          data: [],
        },
      }),
    },
  },
  data: function () {
    return {
      is_show: this.tampilkan,
    };
  },
  watch: {
    tampilkan: function (new_val, old_val) {
      if (old_val !== "") {
        this.is_show = new_val;
      }
    },
    is_show: function (new_val, old_val) {
      if (old_val !== "") {
        if (!new_val) {
          this.$emit("toggleDialog", this.is_show);
        }
      }
    },
  },
  methods: {
    formatedDate(date, format) {
      return moment(date).locale(this.$i18n.locale).format(format);
    },
    formatDate(par) {
      if (!par) return null;
      return moment(par)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    formatDateTime(par) {
      if (!par) return null;
      return moment(par)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY HH:mm:ss")
        .toUpperCase();
    },
    toggleDialog() {
      this.is_show = false;
      this.$emit("toggleDialog", this.is_show);
    },
    downloadLab(param, type) {
      API.get(
        `${process.env.VUE_APP_API_TRUST}diagnosis/get-labreport?order_number=${param}&type=${type}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
        this.download = res;
        window.open(this.download.linkDownload, "_blank");
      });
    },
    downloadMcu(param, type) {
      API.get(
        `${process.env.VUE_APP_API_TRUST}diagnosis/get-mcureport?order_number=${param}&type=${type}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
        this.download = res;
        window.open(this.download.linkDownload, "_blank");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.grey-color {
  background: #eeeeee;
}

.head-vir {
  background: #1a428a;
  color: #fff;
}

.head-vir2 {
  background: #cecece;
  font-weight: bold;
}

// .head-vir2:hover {
// background: #6dc6d6 !important;
// }

.head-vir2 th {
  color: #000 !important;
}

.bold {
  font-weight: bold;
}

.v-data-table table tr td {
  font-size: 12px !important;
}

.mobile {
  display: none;
}

.table-inside {
  width: 100%;
  padding: 0;
}

.table-inside tr td:first-child {
  width: 20%;
}

.mobile tr {
  display: inline-table;
  width: 100%;
}

.mobile .table-inside tr td:first-child {
  width: 20%;
}
@media (min-width: 539px) {
  .v-menu__content {
    max-width: 43%;
  }
}

@media (min-width: 750px) {
  .v-menu__content {
    max-width: 30%;
  }
}
@media (min-width: 810px) {
  .v-menu__content {
    max-width: 33%;
  }
}
@media (min-width: 1000px) {
  .v-menu__content {
    max-width: 25%;
  }
}
@media (min-width: 1030px) {
  .v-menu__content {
    max-width: 20%;
  }
}
@media (max-width: 450px) {
  .v-menu__content {
    max-width: 65%;
  }
  .dialogvirtu.v-dialog {
    margin: 5px !important;
  }
  .tb-wide {
    min-width: 150px;
  }
  .tb-wide2 {
    min-width: 200px;
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }

  .dialogvirtulogo {
    width: 150px;
  }
}
</style>
<style lang="scss">
@media (max-width: 450px) {
  .dialogorderdetail.v-dialog {
    margin: 5px !important;
  }
  .dialogorderdetail .v-card__text {
    padding: 0 10px 20px !important;
  }
}
@media (min-width: 600px) {
  .table-sticky .v-data-table__wrapper {
    overflow: unset;
  }
  .table-sticky thead {
    position: sticky;
    inset-block-start: 0;
    top: 0;
  }
}
@media (max-width: 599px) {
  .table-sticky .v-data-table__wrapper {
    overflow-x: auto;
    overflow-y: auto;
    height: 400px;
  }
  .table-sticky thead tr {
    border-bottom: 0 !important;
    position: sticky;
    top: 0;
    z-index: 2;
  }
}
.category-blue {
  color: #fff !important;
  background: #233e8d !important;
}
.true {
  color: red;
  font-weight: 600;
}
.btn-download-report {
  background: #209fb4;
}
.popup {
  padding: 8px;
  background: white;
  text-transform: uppercase;
  font-size: 12px !important;
  line-height: 16px;
  a {
    text-decoration: none;
    color: black;
  }
}
.title-grid {
  display: grid;
}
.title-grid-h {
  text-align: left;
}
.title-grid-c {
  text-align: left;
  font-size: 8px;
}
.mt5 {
  margin-top: 5px !important;
}
.mb10 {
  margin-bottom: -10px;
}
.mb5 {
  margin-bottom: 5px !important;
}
</style>
